import { CLS, ConditionalEntry, Configuration, FIELD_TYPES, Is, USERDATA_FIELD_TYPES } from '../dtos/index';
import { CfgUtil } from './cfg-util';
import { BaseDataResolver } from './calculation';

export enum CheckError {
  //warnings
  EMPTY_ELEMENT = 'empty-element',
  REQUIRED_BUT_CONDITIONAL = 'conditional-required',
  REFERENCE_TO_FIELD_AFTER = 'reference-to-field-after',
  REFERENCE_TO_CONDITIONAL = 'reference-to-conditional',
  INPUT_AFTER_SUBMIT = 'input-after-submit',
  MULTIPLE_SUBMIT_BTN = 'multiple-submit-btn',
  CONDITION_ON_FIRST_OR_LAST_PAGE = 'condition-on-first-or-last-page',
  //errors
  INVALID_FIELD_REF = 'invalid-field-reference',
  INVALID_BASE_DATA_REF = 'invalid-base-data-reference',
}

export enum CheckResultSeverity {
  ERROR = 'error',
  WARNING = 'warn',
  INFO = 'none',
}

export type CheckResult = {
  name: string;
  refName?: string;
  severity: CheckResultSeverity;
  cls: CLS;
  error: CheckError;
};

/**
 * Checks a configuration for known problems and misconfigurations
 */
export class ConfigurationChecker {
  constructor(
    private readonly cfgUtil: CfgUtil<Configuration>,
    private readonly baseDataResolver: BaseDataResolver,
  ) {}

  public async check(): Promise<CheckResult[]> {
    const checkResults = await Promise.all([
      this.checkEmptyElements(),
      this.checkRequiredButConditional(),
      this.checkFieldReferences(),
      this.checkBaseDataReferences(),
      this.checkReferenceToFieldAfter(),
      this.checkReferenceToConditional(),
      this.checkInputAfterSubmit(),
      this.checkMultipleSubmitBtns(),
      this.checkConditionOnFirstOrLastPage(),
    ]);
    return checkResults.flat();
  }

  /**
   * checks for empty pages and groups
   */
  private checkEmptyElements(): Promise<CheckResult[]> {
    const results: CheckResult[] = [];

    const emptyPages = this.cfgUtil.getPages().filter((page) => page.children.length === 0);
    for (const emptyPage of emptyPages) {
      results.push({
        error: CheckError.EMPTY_ELEMENT,
        severity: CheckResultSeverity.INFO,
        cls: emptyPage.cls,
        name: emptyPage.name,
      });
    }

    const emptyGroups = this.cfgUtil.getGroups().filter((group) => group.children.length === 0);
    for (const emptyGroup of emptyGroups) {
      results.push({
        error: CheckError.EMPTY_ELEMENT,
        severity: CheckResultSeverity.INFO,
        cls: emptyGroup.cls,
        name: emptyGroup.name,
      });
    }
    return Promise.resolve(results);
  }

  /**
   * checks for required fields which are possibly hidden by conditions
   */
  private checkRequiredButConditional(): Promise<CheckResult[]> {
    const results: CheckResult[] = [];

    const conditionalEntries = this.cfgUtil.getEntriesWithConditions();
    for (const entryName of conditionalEntries.keys()) {
      const entry = this.cfgUtil.getEntryByName(entryName, true);
      if (Is.field(entry)) {
        if (entry.config.required === true) {
          results.push({
            error: CheckError.REQUIRED_BUT_CONDITIONAL,
            severity: CheckResultSeverity.INFO,
            cls: entry.cls,
            name: entry.name,
          });
        }
      }
    }

    return Promise.resolve(results);
  }

  /**
   * checks for required fields which are possibly hidden by conditions
   */
  private checkReferenceToFieldAfter(): Promise<CheckResult[]> {
    const results: CheckResult[] = [];

    const fieldRefs = this.cfgUtil.getFieldUsages();
    for (const fieldRef of fieldRefs) {
      try {
        //the reference
        const entryPosition = this.cfgUtil.getAbsolutePosition(fieldRef.entry.name);
        //the field using the reference
        const usagePosition = this.cfgUtil.getAbsolutePosition(fieldRef.usedBy.name);
        if (usagePosition < entryPosition) {
          results.push({
            severity: CheckResultSeverity.WARNING,
            error: CheckError.REFERENCE_TO_FIELD_AFTER,
            cls: fieldRef.usedBy.cls,
            name: fieldRef.usedBy.name,
            refName: fieldRef.entry.name,
          });
        }
      } catch (_) {
        /* empty */
      }
    }

    return Promise.resolve(results);
  }

  /**
   * checks for fields using a reference field which is probably hidden by conditions
   */
  private checkReferenceToConditional(): Promise<CheckResult[]> {
    const results: CheckResult[] = [];

    const fieldRefs = this.cfgUtil.getFieldUsages();
    for (const fieldRef of fieldRefs) {
      try {
        //the reference
        const conditionGroups = this.cfgUtil.getEntryConditions(fieldRef.entry.name);
        if (conditionGroups.length > 0) {
          results.push({
            severity: CheckResultSeverity.WARNING,
            error: CheckError.REFERENCE_TO_CONDITIONAL,
            cls: fieldRef.usedBy.cls,
            name: fieldRef.usedBy.name,
            refName: fieldRef.entry.name,
          });
        }
      } catch (_) {
        /* empty */
      }
    }

    return Promise.resolve(results);
  }

  /**
   * checks for user input fields after a submit button
   */
  private checkInputAfterSubmit(): Promise<CheckResult[]> {
    const results: CheckResult[] = [];

    const submitBtnFields = this.cfgUtil.getFieldsOfType(FIELD_TYPES.SUBMITBTN);
    if (submitBtnFields.length === 0) {
      return Promise.resolve(results);
    }
    const inputFields = this.cfgUtil.getFieldsOfType(USERDATA_FIELD_TYPES);

    for (const submitBtn of submitBtnFields) {
      const submitBtnPos = this.cfgUtil.getAbsolutePosition(submitBtn.name);
      const inputFieldsAfter = inputFields.filter((field) => {
        const fieldPos = this.cfgUtil.getAbsolutePosition(field.name);
        return fieldPos > submitBtnPos;
      });
      if (inputFieldsAfter.length > 0) {
        results.push({
          severity: CheckResultSeverity.WARNING,
          error: CheckError.INPUT_AFTER_SUBMIT,
          cls: submitBtn.cls,
          name: submitBtn.name,
          refName: inputFieldsAfter.shift()?.name,
        });
      }
    }

    return Promise.resolve(results);
  }

  /**
   * checks for references which do not exist
   */
  private async checkBaseDataReferences(): Promise<CheckResult[]> {
    const results: CheckResult[] = [];

    const baseDataReferences = this.cfgUtil.getAllBaseDataReferences();
    for (const baseDataRef of baseDataReferences) {
      const entry = await this.baseDataResolver.resolve(baseDataRef.entry).catch(() => {
        return undefined;
      });
      if (!entry) {
        results.push({
          error: CheckError.INVALID_BASE_DATA_REF,
          cls: baseDataRef.usedBy.cls,
          name: baseDataRef.usedBy.name,
          refName: baseDataRef.entry.name,
          severity: CheckResultSeverity.ERROR,
        });
      }
    }

    return Promise.resolve(results);
  }

  /**
   * checks for references which do not exist
   */
  private checkFieldReferences(): Promise<CheckResult[]> {
    const results: CheckResult[] = [];

    const fieldRefs = this.cfgUtil.getFieldUsages();
    for (const fieldRef of fieldRefs) {
      const entry = this.cfgUtil.getEntryByName(fieldRef.entry.name, false);
      if (entry === undefined) {
        results.push({
          error: CheckError.INVALID_FIELD_REF,
          cls: fieldRef.usedBy.cls,
          name: fieldRef.usedBy.name,
          severity: CheckResultSeverity.ERROR,
        });
      }
    }

    return Promise.resolve(results);
  }

  /**
   * checks for multiple submit buttons
   */
  private checkMultipleSubmitBtns(): Promise<CheckResult[]> {
    const results: CheckResult[] = [];

    const submitBtnFields = this.cfgUtil.getFieldsOfType(FIELD_TYPES.SUBMITBTN);
    if (submitBtnFields.length <= 1) {
      return Promise.resolve(results);
    }

    const submitBtn = submitBtnFields.pop();
    if (submitBtn) {
      results.push({
        severity: CheckResultSeverity.WARNING,
        error: CheckError.MULTIPLE_SUBMIT_BTN,
        cls: submitBtn.cls,
        name: submitBtn.name,
      });
    }

    return Promise.resolve(results);
  }

  /**
   * checks for conditions on first or last page
   */
  private checkConditionOnFirstOrLastPage(): Promise<CheckResult[]> {
    const results: CheckResult[] = [];

    const pages = this.cfgUtil.getPages();
    if (pages.length === 0) {
      return Promise.resolve([]);
    }

    const firstPage = this.cfgUtil.getEntryWrapperByName<ConditionalEntry>(pages[0].name);
    if (firstPage.condition) {
      results.push({
        severity: CheckResultSeverity.WARNING,
        error: CheckError.CONDITION_ON_FIRST_OR_LAST_PAGE,
        cls: firstPage.entry.cls,
        name: firstPage.entry.name,
      });
    }

    if (pages.length === 1) {
      return Promise.resolve(results);
    }
    const lastPage = this.cfgUtil.getEntryWrapperByName<ConditionalEntry>(pages[pages.length - 1].name);
    if (lastPage.condition) {
      results.push({
        severity: CheckResultSeverity.WARNING,
        error: CheckError.CONDITION_ON_FIRST_OR_LAST_PAGE,
        cls: lastPage.entry.cls,
        name: lastPage.entry.name,
      });
    }
    return Promise.resolve(results);
  }
}
