import { Calculation, CalculationRef, CalculationRefResolver, CfgUtil } from '../../../index';

/**
 * Resolves a calculation reference to a calculation.
 */
export class CfgUtilCalculationRefResolver implements CalculationRefResolver {
  constructor(private readonly cfgUtil: CfgUtil) {}

  resolve(calculationRef: CalculationRef): Promise<Calculation | undefined> {
    return Promise.resolve(this.cfgUtil.getCalculation(calculationRef.name)).then((cfgCalculation) =>
      cfgCalculation ? cfgCalculation.calculation : undefined,
    );
  }
}
