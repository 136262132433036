import { Injectable } from '@angular/core';
import { ContextService } from '@kfd/core';
import { CfgException } from '../../common/cfg-exception';

export const CFG_CONTEXT_SERVICE = 'CfgContextService';

@Injectable()
export class CfgContextService implements ContextService {
  private _projectId = '';
  private _configuratorId = '';
  private _requestId = '';

  constructor() {
    // console.log('CfgContextService created');
  }

  get projectId(): string {
    if (!this._projectId) {
      throw new CfgException('No project id available');
    }
    return this._projectId;
  }

  set projectId(value: string) {
    this._projectId = value;
  }

  get configuratorId(): string {
    if (!this._configuratorId) {
      throw new CfgException('No configuration id available');
    }
    return this._configuratorId;
  }

  set configuratorId(value: string) {
    this._configuratorId = value;
  }

  get requestId(): string {
    if (!this._requestId) {
      throw new CfgException('No request id available');
    }
    return this._requestId;
  }

  set requestId(value: string) {
    this._requestId = value;
  }

  hasMandantId(): boolean {
    return !!this._projectId;
  }

  hasConfiguratorId(): boolean {
    return !!this._configuratorId;
  }
}
