import { BaseDataRef, BaseDataResolver, ContextService, SelectionData } from '@kfd/core';
import { Inject, Injectable } from '@angular/core';
import { CFG_CONTEXT_SERVICE, DATA_PROVIDER, DataProvider } from '../../../index';
import { map } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class ConfigurationBasedataResolver implements BaseDataResolver {
  constructor(
    @Inject(CFG_CONTEXT_SERVICE) private readonly contextService: ContextService,
    @Inject(DATA_PROVIDER) private readonly dataProvider: DataProvider,
  ) {}

  resolve(baseDataRef: BaseDataRef): Promise<SelectionData | undefined> {
    return firstValueFrom(
      this.dataProvider
        .getDataByIdentifier(this.contextService.projectId, this.contextService.configuratorId, baseDataRef.name)
        .pipe(map((data) => (data ? data : undefined))),
    );
  }
}
