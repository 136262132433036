import { EventEmitter, Inject, Injectable, InjectionToken } from '@angular/core';
import { combineLatest, Observable, Subscriber } from 'rxjs';
import { Create, FormValue, FormValueMap, FormValueUtil, unvTimeout } from '@kfd/core';
import { Persistence } from './persistence/persistence';
import { Key } from './persistence/key';
import { map } from 'rxjs/operators';
import { CfgException } from '../../common/cfg-exception';

export const PERSISTENCE_TOKEN = new InjectionToken<Persistence>('SomeToken');

@Injectable()
export class ConfigurationStateService {
  /**
   * emits on value change with the key
   */
  valueChange: EventEmitter<string> = new EventEmitter<string>();
  clear: EventEmitter<void> = new EventEmitter<void>();
  persistence: Persistence;

  constructor(@Inject(PERSISTENCE_TOKEN) persistence: Persistence) {
    this.persistence = persistence;
  }

  public removeAllValues(soft = false): void {
    if (soft) {
      unvTimeout(() => {
        this.persistence.clear();
      }, 1000);
      return;
    }
    this.persistence.clear();
    this.clear.emit();
  }

  public setValue(key: string, value: FormValue, enable = true) {
    if (!FormValueUtil.isValid(value)) {
      throw new CfgException('Invalid form value:' + JSON.stringify(value));
    }
    this.persistence.set(new Key(key), value);
    if (enable) {
      this.enable(key);
    }
    // console.log('debug', 'state-save', key, value);
    this.valueChange.emit(key);
  }

  public unsetValue(key: string) {
    if (this.hasValue(key)) {
      this.setValue(key, Create.emptyFormValue());
    }
  }

  public enable(key: string) {
    this.persistence.enable(new Key(key));
    if (this.hasValue(key)) {
      this.valueChange.emit(key);
    }
  }

  public disable(key: string) {
    if (this.hasValue(key)) {
      this.persistence.disable(new Key(key));
      this.valueChange.emit(key);
    }
  }

  public hasValue(key: string): boolean {
    return this.persistence.has(new Key(key));
  }

  public getValue(key: string): FormValue {
    const value = this.hasValue(key) ? this.persistence.get(new Key(key)) : Create.emptyFormValue();
    value.key = key;
    return value;
  }

  /**
   * value change listener for a specific field
   */
  public onFieldValueChange(keyToSubscribe: string, onEmpty?: boolean): Observable<FormValue> {
    return this.onValueChange(keyToSubscribe, onEmpty);
  }

  /**
   * value change listener for a list of field
   */
  public onFieldsValueChange(keysToSubscribe: string[], onEmpty?: boolean): Observable<FormValue[]> {
    const observables: Observable<FormValue>[] = keysToSubscribe.map((keyToSubscribe) =>
      this.onValueChange(keyToSubscribe, onEmpty),
    );
    return combineLatest(observables);
  }

  public valueMapChange(): Observable<FormValueMap> {
    return this.onValueChange().pipe(map(() => this.getValueMap()));
  }

  /**
   * value change listener for all fields
   */
  public onValueChange(keyToSubscribe?: string, onEmpty?: boolean): Observable<FormValue> {
    return new Observable((observer) => {
      // send initial
      if (keyToSubscribe) {
        this.sendKey(observer, keyToSubscribe);
      } else {
        const keys = this.persistence.list();
        for (const key of keys) {
          this.sendKey(observer, key.toString());
        }
      }

      // send on change
      this.valueChange.subscribe((key) => {
        if (keyToSubscribe === key || keyToSubscribe === undefined) {
          this.sendKey(observer, key, onEmpty);
        }
      });
      this.clear.subscribe(() => {
        observer.next(Create.emptyFormValue());
      });
    });
  }

  public getValueMap(fields: string[] = []): FormValueMap {
    const valueMap: FormValueMap = new Map();
    if (fields.length === 0) {
      fields = this.persistence.list().map((value) => value.toString());
    }
    fields.forEach((name) => {
      if (this.hasValue(name)) {
        const formValue = this.getValue(name);
        valueMap.set(name, formValue);
      }
    });
    return valueMap;
  }

  public fireAllValueChange(): void {
    this.persistence.list().forEach((key) => {
      this.valueChange.emit(key.toString());
    });
  }

  private sendKey(observer: Subscriber<FormValue>, key: string, onEmpty?: boolean): void {
    const value = this.getValue(key);
    if (!value && !onEmpty) {
      return;
    }
    observer.next(value);
  }
}
