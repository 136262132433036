import { ExportData, Id } from './cms.dto';
import { USER_SCOPES } from './web.dto';
import {
  AliasValue,
  BooleanFieldConfig,
  Calculation,
  CalculationFunction,
  CfgCalculation,
  ChildWrapper,
  Configuration,
  ConfiguratorField,
  ConfiguratorFieldGroup,
  ConfiguratorPage,
  ConfiguratorSettings,
  DataHandler,
  DATAHANDLER_TYPES,
  DateFieldConfig,
  DisplayDataFieldConfig,
  DynamicDataHandler,
  Field,
  FieldConfig,
  FieldGroup,
  GenericEntry,
  Info,
  LocalCodes,
  MultiSelectFieldConfig,
  NumericFieldConfig,
  Page,
  ParentEntry,
  RelativeDate,
  SelectFieldConfig,
  SelectionData,
  SimpleListHandler,
  START_DATES,
  TextFieldConfig,
  ValueCalculation,
} from './cfg.dto';
import { EntryMeta, PUBLISHED_STATE } from './cms-cfg.dto';
import { CalculationDetails } from '../configurator';

export const REMOVE_DELETED_CFG_AFTER_DAYS = 14;
export const SAVE_DATE_FORMAT = 'YYYY-MM-DD';
export const SAVE_DATE_FORMAT_PRIME = 'yy-mm-dd';

export enum CLS {
  NONE = '',
  PROJECT = 'Project',
  PAGE_WRAPPER = 'ConfiguratorPage',
  FIELD_GROUP_WRAPPER = 'ConfiguratorGroup',
  FIELD_WRAPPER = 'ConfiguratorField',
  PAGE = 'Page',
  FIELD_GROUP = 'Group',
  FIELD = 'Field',
  PAGE_REF = 'PageRef',
  FIELD_REF = 'FieldRef',
  CALCULATION_REF = 'CalculationRef',
  DATE_REF = 'DateRef',
  FIELD_CONFIG = 'FieldConfig',
  BASE_DATA_REF = 'BaseDataRef',
  CONDITION = 'Condition',
  CONDITION_GROUP = 'ConditionGroup',
  CONFIGURATION = 'Configuration',
  CONFIGURATION_SETTINGS = 'ConfigurationSettings',
  CALCULATION = 'Calculation',
  CALCULATION_RESULT = 'CalculationResult',
  CALCULATION_FUNCTION = 'Function',
  VALUE_LABEL = 'ValueLabel',
  INPUT_VALUE = 'InputValue',
  IMAGE = 'Image',
  SELECTION_VALUE = 'SelectionValue',
  DATA_HANDLER = 'DataHandler',
  ALIAS_VALUE = 'AliasValue',
  RELATIVE_DATE = 'RelativeDate',
  SELECTION_DATA = 'SelectionData',
  FORM_VALUE = 'FormValue',
  CFG_EXPORT = 'CfgExport',
  ASSET_EXPORT = 'AssetExport',
  FILTER = 'FILTER',
  BASE_DATA_TEMPLATE = 'BaseDataTemplate',
  BASE_DATA_TEMPLATE_VALUE = 'BaseDataTemplateValue',
}

export enum REQ_PARAMS {
  PROJECT_ID = 'projectId',
  CONFIGURATION_ID = 'configurationId',
  REQUEST_ID = 'requestId',
  REQUEST_CODE = 'requestCode',
  CUSTOMER_CODE = 'customerCode',
  INVITATION_ID = 'invitationId',
  CHILD_ENTRY_ID = 'childEntryId',
  ENTRY_ID = 'entryId',
  KEY = 'key',
  EMAIL = 'email',
}

export enum LOGICAL_OPERATOR {
  AND = 'and',
  OR = 'or',
}

export enum CONDITIONAL_OPERATOR {
  CONTAINS = 'contains',
  EQUALS = 'equals',
  NOT_EQUALS = 'notEquals',
  GREATER = 'greater',
  LESS = 'less',
  GREATER_OR_EQUAL = 'greaterOrEqual',
  LESS_OR_EQUAL = 'lessOrEqual',
  EMPTY = 'empty',
  NOT_EMPTY = 'notEmpty',
}

export enum DATA_VALUE_TYPE {
  BOOL = 'bool',
  STRING = 'string',
  NUMERIC = 'numeric',
  EMPTY = 'empty',
  DATE = 'date',
  VOID = 'void',
}

export enum FIELD_TYPES {
  SUMMARY = 'summary',
  SUBMITBTN = 'submitbtn',
  RESETBTN = 'resetbtn',
  DISPLAY_DATA = 'readonly',
  YESNO = 'yesno',
  NUMBER = 'number',
  TEXT = 'text',
  SELECT = 'select',
  DATE = 'date',
}

export enum ICON {
  MENU = 'pi-ellipsis-v',
  INFO = 'pi-info-circle',
  SETTINGS = 'pi-cog',
  PROJECT = 'pi-box',
  USER = 'pi-user',
  DATETIME = 'pi-clock',
  SEARCH = 'pi-search',
  SIGN_IN = 'pi-sign-in',
  SIGN_OUT = 'pi-sign-out',
  ADD = 'pi-plus',
  NOTE = 'pi-paperclip',
  NOTIFICATION = 'pi-bell',
  EDIT = 'pi-pencil',
  COPY = 'pi-copy',
  DELETE = 'pi-trash',
  MOVE_Y = 'pi-arrows-v',
  MOVE = 'pi-arrows-alt',
  DECLINE = 'pi-times',
  RESET = 'pi-undo',
  ACCEPT = 'pi-check',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  CORRECT = 'pi-check',
  SAVE = 'pi-save',
  HOME = 'pi-home',
  DOCU = 'pi-book',
  SERVICE = 'pi-comments',
  CONFIGURATIONS = 'pi-th-large',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  CONFIGURATION = 'pi-cog',
  PAGE = 'pi-file',
  GROUP = 'icon-group',
  ENTRY = 'icon-entry',
  ENTRY_TEXT = 'icon-field-text',
  ENTRY_NUMBER = 'icon-field-numeric',
  ENTRY_DISPLAY_DATA = 'icon-field-readonly',
  ENTRY_SELECT = 'icon-field-select',
  ENTRY_YESNO = 'icon-field-bool',
  ENTRY_DATE = 'icon-field-date',
  ENTRY_ACTION = 'icon-field-action',
  ENTRY_CONDITION = 'icon-condition',
  ENTRY_SUMMARY = 'icon-summary',
  CONDITION = 'pi-dollar',
  REFRESH = 'pi-refresh',
  STATISTIC = 'pi-chart-line',
  BACK = 'pi-chevron-left',
  NEXT = 'pi-chevron-right',
  BASE_DATA = 'pi-table',
  CFG_REQUESTS = 'pi-shopping-cart',
  CI = 'pi-id-card',
  USERS = 'pi-users',
  MEMBERSHIP = 'pi-wallet',
  WARN = 'pi-exclamation-triangle',
  SHOW = 'pi-eye',
  PUBLISH = 'pi-globe',
  UNPUBLISH = 'pi-stop-circle',
  UPLOAD = 'pi-upload',
  TEXT = 'pi-align-left',
  FILTER = 'pi-filter',
  FILTER_REMOVE = 'pi-filter-slash',
  SORT = 'pi-sort',
  NO_SORT = 'pi-ban',
  SORT_DOWN = 'pi-caret-down',
  SORT_UP = 'pi-caret-up',
  STATUS = 'pi-directions',
  EXPORT = 'pi-download',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  DOWNLOAD = 'pi-download',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  IMPORT = 'pi-upload',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  PROFILE = 'pi-user',
  RECYCLE = 'pi-replay',
  LINK = 'pi-link',
  LINK_EXTERNAL = 'pi-external-link',
  FAVORITE = 'pi-heart',
  FAVORITE_SELECTED = 'pi-heart-fill',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  ENABLED = 'pi-eye',
  DISABLED = 'pi-eye-slash',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  VISIBLE = 'pi-eye',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  INVISIBLE = 'pi-eye-slash',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  DROP = 'pi-download',
  MINIMIZE = 'pi-window-minimize',
  PREVIEW = 'pi-play',
  SYNC = 'pi-sync',
  INTEGRATION = 'pi-file-import',
  DATABASE = 'pi-database',
  EMAIL = 'pi-at',
  PHONE = 'pi-phone',
  TAG = 'pi-tag',
  LOCKED = 'pi-lock',
  EXPAND = 'pi-caret-right',
  COLLAPSE = 'pi-caret-left',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  FILE = 'pi-file',
  IMAGE = 'pi-image',
  USAGES = 'pi-arrows-h',
  PRIVACY = 'pi-shield',
  DATE = 'pi-calendar',
  TEMPLATE = 'pi-clone',
  CALCULATION = 'pi-calculator',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  ADDITION = 'pi-plus',
  SUBTRACTION = 'pi-minus',
  DIVISION = 'icon-divide',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  MULTIPLICATION = 'pi-times',
  ALIAS = 'pi-receipt',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  CALCULATION_REF = 'pi-link',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  EXCLUDE = 'pi-ban',
}

export const FIELD_TYPES_TO_DATA_VALUE_TYPES = {
  [FIELD_TYPES.SUMMARY]: DATA_VALUE_TYPE.VOID,
  [FIELD_TYPES.RESETBTN]: DATA_VALUE_TYPE.VOID,
  [FIELD_TYPES.DISPLAY_DATA]: DATA_VALUE_TYPE.VOID,
  [FIELD_TYPES.SUBMITBTN]: DATA_VALUE_TYPE.STRING,
  [FIELD_TYPES.YESNO]: DATA_VALUE_TYPE.BOOL,
  [FIELD_TYPES.NUMBER]: DATA_VALUE_TYPE.NUMERIC,
  [FIELD_TYPES.TEXT]: DATA_VALUE_TYPE.STRING,
  [FIELD_TYPES.SELECT]: DATA_VALUE_TYPE.STRING,
  [FIELD_TYPES.DATE]: DATA_VALUE_TYPE.DATE,
};

//contains only fields with custom user input (no pre defined values)
export const INPUT_FIELD_TYPES = [FIELD_TYPES.NUMBER, FIELD_TYPES.TEXT, FIELD_TYPES.DATE];
export const INPUT_VALUE_TYPES = [DATA_VALUE_TYPE.NUMERIC, DATA_VALUE_TYPE.STRING, DATA_VALUE_TYPE.DATE];

//contains all fields which stores data
export const VALUE_FIELD_TYPES: FIELD_TYPES[] = [
  FIELD_TYPES.NUMBER,
  FIELD_TYPES.TEXT,
  FIELD_TYPES.DATE,
  FIELD_TYPES.YESNO,
  FIELD_TYPES.SELECT,
  FIELD_TYPES.SUBMITBTN,
  FIELD_TYPES.DISPLAY_DATA,
];

//contains all fields where a user can change/insert values
export const USERDATA_FIELD_TYPES: FIELD_TYPES[] = [
  FIELD_TYPES.NUMBER,
  FIELD_TYPES.TEXT,
  FIELD_TYPES.DATE,
  FIELD_TYPES.YESNO,
  FIELD_TYPES.SELECT,
];

export enum SELECT_FIELD_LAYOUTS {
  BUTTON = 'button',
  DROPDOWN = 'dropdown',
}

export interface InsertResult {
  _id: Id;
}

export interface TypedObject {
  cls: string;
}

export interface Image {
  cls: CLS.IMAGE;
  url: string;
  thumbnail?: string;
  title: string;
  description?: string;
}

export interface ValueLabel {
  cls: CLS.VALUE_LABEL;
  text: string;
  format: string;
}

export interface Tag {
  name: string;
  label: string;
}

export interface FieldRef {
  cls: CLS.FIELD_REF;
  name: string;
  // name of the sub property in base data or multi value fields
  property?: string;
  label?: ValueLabel;
  defaultValue?: BaseInputValue;
}

/**
 * for dynamic date references like "today"
 */
export interface DateRef {
  cls: CLS.DATE_REF;
  name: START_DATES;
}

export interface PageRef {
  cls: CLS.PAGE_REF;
  name: string;
}

/**
 * reference to a single base data entry
 */
export interface BaseDataRef {
  cls: CLS.BASE_DATA_REF;
  name: string;
}

/**
 * reference to a specific value of a single base data entry
 */
export interface BaseDataValueRef extends BaseDataRef {
  // name of the value property
  property: string;
}

export interface CalculationRef {
  cls: CLS.CALCULATION_REF;
  name: string;
}

export type ValueTypes = number | string | boolean;

export interface Value {
  cls: CLS.INPUT_VALUE | CLS.SELECTION_VALUE;
  type: DATA_VALUE_TYPE;
  value?: ValueTypes;
  label?: ValueLabel;
}

export interface InputValueProperties {
  min?: number;
  max?: number;
  minFractionDigits?: number;
  showButtons?: boolean;
  prefix?: string;
  suffix?: string;
  mode?: string;
}

export interface BaseInputValue extends Value {
  cls: CLS.INPUT_VALUE;
  type: DATA_VALUE_TYPE;
  value?: ValueTypes;
}

export interface InputValue extends BaseInputValue {
  identifier?: string;
  label?: ValueLabel;
  properties?: InputValueProperties;
}

export interface SelectionValue extends Value {
  cls: CLS.SELECTION_VALUE;
  identifier: string;
}

export interface BoolInputValue extends InputValue {
  type: DATA_VALUE_TYPE.BOOL;
  value: boolean;
}

export interface NumericInputValue extends InputValue {
  type: DATA_VALUE_TYPE.NUMERIC;
  properties?: InputValueProperties;
  value: number;
}

export interface TextInputValue extends InputValue {
  type: DATA_VALUE_TYPE.STRING;
  value: string;
}

export interface DateInputValue extends InputValue {
  type: DATA_VALUE_TYPE.DATE;
  value: string;
}

/**
 * represents a value which has not been touched by the user
 */
export interface NoInputValue extends InputValue {
  type: DATA_VALUE_TYPE.VOID;
}

/**
 * represents a value which is empty and the type is not relevant
 * alternatively if the type is relevant a specific input value can be used and value can be set to undefined
 */
export interface EmptyInputValue extends InputValue {
  type: DATA_VALUE_TYPE.EMPTY;
}

export enum DATA_TYPES {
  SELECTION = 'selection',
  INPUT = 'input',
}

export enum FORM_VALUE_TYPES {
  EMPTY = 'empty',
  SINGLE_SELECTION = 'single-selection',
  MULTI_SELECTION = 'multi-selection',
  SINGLE = 'single',
  MULTI = 'multi',
}

export interface FormValue {
  cls: CLS.FORM_VALUE;
  type: FORM_VALUE_TYPES;
  key: string;
}

export interface EmptyFormValue extends FormValue {
  cls: CLS.FORM_VALUE;
  type: FORM_VALUE_TYPES.EMPTY;
}

export interface SingleFormValue<T extends InputValue = InputValue> extends FormValue {
  cls: CLS.FORM_VALUE;
  type: FORM_VALUE_TYPES.SINGLE;
  input: T;
}

export interface MultiFormValue extends FormValue {
  cls: CLS.FORM_VALUE;
  type: FORM_VALUE_TYPES.MULTI;
  valueMap: { [key: string]: InputValue };
}

export interface SingleSelectionFormValue extends FormValue {
  cls: CLS.FORM_VALUE;
  type: FORM_VALUE_TYPES.SINGLE_SELECTION;
  selection: SelectionData;
}

export interface MultiSelectionFormValue extends FormValue {
  cls: CLS.FORM_VALUE;
  type: FORM_VALUE_TYPES.MULTI_SELECTION;
  selection: SelectionData[];
}

export interface PrintValue {
  label: string;
  key: string;
  value: string;
  style?: 'disabled';
}

export interface LocalConfig {
  thousandSeparator: string;
  decimalSeparator: string;
  dateFormat: string;

  ngDateFormat: string;
}

export const LOCAL_CODE_CONFIG: Record<string, LocalConfig> = {
  [LocalCodes.DE_DE]: {
    thousandSeparator: '.',
    decimalSeparator: ',',
    dateFormat: 'DD.MM.YYYY',
    ngDateFormat: 'dd.mm.yy',
  },
  [LocalCodes.EN_US]: {
    thousandSeparator: ',',
    decimalSeparator: '.',
    dateFormat: 'MM/DD/YYYY',
    ngDateFormat: 'mm/dd/yy',
  },
};

// /**
//  * contains general internal settings which are *NOT* versioned
//  */
// export interface ConfiguratorInternalSettings {
//
// }

// export interface Configuration {
//   id: string;
//   name: string;
//   label: string;
//   configurator: string;
//   values: string[];
//   image: Image;
// }

export interface OverviewPage {
  name: string;
  label: string;
  info?: Info;
  // configurations: Configuration[];
}

export interface ProjectInfo {
  name: string;
  slogan: string;
  imprint: string;
  privacyLink: string;
  domain: string;
}

export interface ProjectCI {
  logo?: Image;
  mainColor: string;
  mainTextColor: string;
  highlightColor: string;
  highlightTextColor: string;
  fontName: string;
}

export interface PublicProject {
  info: ProjectInfo;
  ci: ProjectCI;
}

export interface PendingRequest {
  projectId: string;
  configurationId: string;
  cfgVersion: number;
}

export interface RequestForm {
  id: string;
  project: PublicProject;
  configuration: Configuration;
}

export interface SuccessfulRequestSubmission {
  success: true;
  requestCode: string;
  customerCode?: string;
  link: string;
}

export enum SUBMISSION_ERROR_CODES {
  INVALID_ID,
  VALIDATION_ERROR,
}

export interface FailedRequestSubmission {
  success: false;
  code: SUBMISSION_ERROR_CODES;
  errors?: { [k: string]: ValidationError };
}

export type RequestSubmissionBody = { [key: string]: FormValue };
export type RequestSubmissionResponse = SuccessfulRequestSubmission | FailedRequestSubmission;

export interface Project {
  cls: CLS.PROJECT;
  name: string;
  label: string;
  info?: ProjectInfo;
  ci?: ProjectCI;
  pages?: OverviewPage[];
  configurators?: Configuration[];
}

export enum VALIDATION_ERROR_TYPE {
  REQUIRED = 'required',
  INVALID = 'invalid',
  IRRELEVANT = 'irrelevant',
}

export enum VALIDATION_ERROR_DETAILS {
  UNKNOWN = 'unknown',
  EMPTY = 'empty',
  TO_LONG = 'tolong',
  TO_SHORT = 'toshort',
  TO_SMALL = 'tosmall',
  TO_HIGH = 'tohigh',
  INV_CHR = 'invchar',
  INV_NUMBER = 'invnr',
  INV_FORMAT = 'invformat',
}

export type ValidationResult = ValidationError | undefined;
export type ValidationMap = Map<string, ValidationResult>;

export interface ValidationError {
  type: VALIDATION_ERROR_TYPE | undefined;
  detail: VALIDATION_ERROR_DETAILS | undefined;
  data?: Record<string, string | number>;
}

export interface Invitation {
  _id: Id;
  email: string;
  projectId: Id;
  mandantName: string;
  scopes: USER_SCOPES[];
  meta: EntryMeta;
}

export interface MetaData {
  created: Date;
  createdBy: Id;
  updated?: Date;
  updatedBy?: Id;
  projectId?: Id;
  state?: PUBLISHED_STATE;
}

export const ALLOWED_FIELD_CLS_VALUES = {
  [CLS.CONFIGURATION]: {
    children: [CLS.PAGE_WRAPPER + '[]'],
  },
  [CLS.PAGE_WRAPPER]: {
    children: [CLS.PAGE],
  },
  [CLS.PAGE]: {
    children: [CLS.FIELD_GROUP_WRAPPER + '[]', CLS.FIELD_WRAPPER + '[]'],
  },
  [CLS.FIELD_GROUP_WRAPPER]: {
    entry: [CLS.FIELD_GROUP],
  },
  [CLS.FIELD_GROUP]: {
    children: [CLS.FIELD_WRAPPER + '[]'],
  },
  [CLS.FIELD_WRAPPER]: {
    entry: [CLS.FIELD],
  },
  [CLS.FIELD]: {
    config: [CLS.FIELD_CONFIG],
  },
};

export const CAN_MOVE_TO: Record<string, CLS[]> = {
  [CLS.PAGE]: [CLS.CONFIGURATION],
  [CLS.FIELD_GROUP]: [CLS.PAGE],
  [CLS.FIELD]: [CLS.FIELD_GROUP, CLS.PAGE],
};

export const WRAPPER_CAN_MOVE_TO: Record<string, CLS[]> = {
  [CLS.PAGE_WRAPPER]: [CLS.CONFIGURATION],
  [CLS.FIELD_GROUP_WRAPPER]: [CLS.PAGE],
  [CLS.FIELD_WRAPPER]: [CLS.FIELD_GROUP, CLS.PAGE],
};

export const ALLOWED_CHILDREN: Record<string, CLS[]> = {
  [CLS.CONFIGURATION]: [CLS.PAGE_WRAPPER],
  [CLS.PAGE]: [CLS.FIELD_GROUP_WRAPPER, CLS.FIELD_WRAPPER],
  [CLS.FIELD_GROUP]: [CLS.FIELD_WRAPPER],
};

export type DateValueTypes = DateInputValue | RelativeDate | EmptyInputValue;

export class Is {
  public static id(o: unknown): o is Id {
    if (!!o && typeof o === 'string') {
      return (o as string).match(/^[0-9a-fA-F]{24}$/) !== null;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return !!o && (o as any)['_bsontype'] === 'ObjectId';
  }

  public static genericEntry(o: unknown): o is GenericEntry {
    return !!((o as GenericEntry)?.cls && (o as GenericEntry)?.name);
  }

  public static configuration(o: unknown): o is Configuration {
    return this.ofType(o as TypedObject, CLS.CONFIGURATION);
  }

  public static cfgSetting(o: unknown): o is ConfiguratorSettings {
    return this.ofType(o as TypedObject, CLS.CONFIGURATION_SETTINGS);
  }

  public static parentEntry(o: unknown): o is ParentEntry {
    return (
      this.ofType(o as TypedObject, CLS.CONFIGURATION) ||
      this.ofType(o as TypedObject, CLS.PAGE) ||
      this.ofType(o as TypedObject, CLS.FIELD_GROUP)
    );
  }

  public static page(o: unknown): o is Page {
    return this.ofType(o as TypedObject, CLS.PAGE);
  }

  public static wrapperEntry(o: unknown): o is ChildWrapper {
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-prototype-builtins
      o.hasOwnProperty('entry') &&
      (this.ofType(o as TypedObject, CLS.FIELD_WRAPPER) ||
        this.ofType(o as TypedObject, CLS.FIELD_GROUP_WRAPPER) ||
        this.ofType(o as TypedObject, CLS.PAGE_WRAPPER))
    );
  }

  public static pageWrapper(o: unknown): o is ConfiguratorPage {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-prototype-builtins
    return o.hasOwnProperty('entry') && this.ofType(o.entry as TypedObject, CLS.PAGE);
  }

  public static fieldGroup(o: unknown): o is FieldGroup {
    return this.ofType(o as TypedObject, CLS.FIELD_GROUP);
  }

  public static fieldGroupWrapper(o: unknown): o is ConfiguratorFieldGroup {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-prototype-builtins
    return o.hasOwnProperty('entry') && this.ofType(o.entry as TypedObject, CLS.FIELD_GROUP);
  }

  public static field<T = FieldConfig>(o: unknown): o is Field<T> {
    return this.ofType(o as TypedObject, CLS.FIELD);
  }

  public static textField(o: unknown): o is Field<TextFieldConfig> {
    return Is.field(o) && o.config.type === FIELD_TYPES.TEXT;
  }

  public static numberField(o: unknown): o is Field<NumericFieldConfig> {
    return Is.field(o) && o.config.type === FIELD_TYPES.NUMBER;
  }

  public static boolField(o: unknown): o is Field<BooleanFieldConfig> {
    return Is.field(o) && o.config.type === FIELD_TYPES.YESNO;
  }

  public static dateField(o: unknown): o is Field<DateFieldConfig> {
    return Is.field(o) && o.config.type === FIELD_TYPES.DATE;
  }

  public static selectField(o: unknown): o is Field<SelectFieldConfig> {
    return Is.field(o) && o.config.type === FIELD_TYPES.SELECT;
  }

  public static multiSelectField(o: unknown): o is Field<MultiSelectFieldConfig> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return Is.selectField(o) && Is.multiSelectFieldConfig(o.config);
  }

  public static multiSelectFieldConfig(o: unknown): o is MultiSelectFieldConfig {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return Is.fieldConfig(o) && (o['min']?.value > 1 || o['max']?.value > 1);
  }

  public static displayDataField(o: unknown): o is Field<DisplayDataFieldConfig> {
    return Is.field(o) && o.config.type === FIELD_TYPES.DISPLAY_DATA;
  }

  public static fieldWrapper(o: unknown): o is ConfiguratorField {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-prototype-builtins
    return o.hasOwnProperty('entry') && this.ofType(o.entry as TypedObject, CLS.FIELD);
  }

  public static calculation(o: unknown): o is Calculation {
    return this.ofType(o as TypedObject, CLS.CALCULATION);
  }

  public static cfgCalculation(o: unknown): o is CfgCalculation {
    return (
      o !== undefined && (o as CfgCalculation).name !== undefined && Is.calculation((o as CfgCalculation).calculation)
    );
  }

  /**
   * Every calculation can be used as CalculationDetails
   */
  public static calculationDetails(o: unknown): o is CalculationDetails {
    return this.ofType(o as TypedObject, CLS.CALCULATION);
  }

  public static valueCalculation(o: unknown): o is ValueCalculation {
    return this.ofType(o as TypedObject, CLS.CALCULATION);
  }

  public static calculationFunction(o: unknown): o is CalculationFunction {
    return this.ofType(o as TypedObject, CLS.CALCULATION_FUNCTION);
  }

  public static value(o: unknown): o is Value {
    return this.ofType(o as TypedObject, CLS.INPUT_VALUE) || this.ofType(o as TypedObject, CLS.SELECTION_VALUE);
  }

  public static inputValue(o: unknown): o is InputValue {
    return this.ofType(o as TypedObject, CLS.INPUT_VALUE);
  }

  public static textInputValue(o: unknown): o is TextInputValue {
    return Is.inputValue(o) && o.type === DATA_VALUE_TYPE.STRING;
  }

  public static numericInputValue(o: unknown): o is NumericInputValue {
    return Is.inputValue(o) && o.type === DATA_VALUE_TYPE.NUMERIC;
  }

  public static boolInputValue(o: unknown): o is BoolInputValue {
    return Is.inputValue(o) && o.type === DATA_VALUE_TYPE.BOOL;
  }

  public static dateInputValue(o: unknown): o is DateInputValue {
    return Is.inputValue(o) && o.type === DATA_VALUE_TYPE.DATE;
  }

  public static emptyInputValue(o: unknown): o is DateInputValue {
    return Is.inputValue(o) && o.type === DATA_VALUE_TYPE.EMPTY;
  }

  public static noInputValue(o: unknown): o is NoInputValue {
    return Is.inputValue(o) && o.type === DATA_VALUE_TYPE.VOID;
  }

  public static singleFormValue(o: unknown): o is SingleFormValue {
    return this.formValue(o) && o.type === FORM_VALUE_TYPES.SINGLE;
  }

  public static singleSelectionFormValue(o: unknown): o is SingleSelectionFormValue {
    return this.formValue(o) && o.type === FORM_VALUE_TYPES.SINGLE_SELECTION;
  }

  public static multiSelectionFormValue(o: unknown): o is MultiSelectionFormValue {
    return this.formValue(o) && o.type === FORM_VALUE_TYPES.MULTI_SELECTION;
  }

  public static multiFormValue(o: unknown): o is MultiFormValue {
    return this.formValue(o) && o.type === FORM_VALUE_TYPES.MULTI;
  }

  public static emptyFormValue(o: unknown): o is EmptyFormValue {
    return this.formValue(o) && o.type === FORM_VALUE_TYPES.EMPTY;
  }

  public static textFormValue(o: unknown): o is SingleFormValue<TextInputValue> {
    return this.singleFormValue(o) && this.textInputValue(o.input);
  }

  public static numericFormValue(o: unknown): o is SingleFormValue<NumericInputValue> {
    return this.singleFormValue(o) && this.numericInputValue(o.input);
  }

  public static boolFormValue(o: unknown): o is SingleFormValue<BoolInputValue> {
    return this.singleFormValue(o) && this.boolInputValue(o.input);
  }

  public static dateFormValue(o: unknown): o is SingleFormValue<DateInputValue> {
    return this.singleFormValue(o) && this.dateInputValue(o.input);
  }

  public static formValue(o: unknown): o is FormValue {
    return this.ofType(o as TypedObject, CLS.FORM_VALUE);
  }

  public static dateDataValue(o: unknown): o is DateInputValue {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.ofType(o as TypedObject, CLS.INPUT_VALUE) && o.type === DATA_VALUE_TYPE.DATE;
  }

  public static aliasValue(o: unknown): o is AliasValue {
    return this.ofType(o as TypedObject, CLS.ALIAS_VALUE);
  }

  public static fieldRef(o: unknown): o is FieldRef {
    return this.ofType(o as TypedObject, CLS.FIELD_REF);
  }

  public static baseDataRef(o: unknown): o is BaseDataRef {
    return this.ofType(o as TypedObject, CLS.BASE_DATA_REF);
  }

  public static calculationRef(o: unknown): o is CalculationRef {
    return this.ofType(o as TypedObject, CLS.CALCULATION_REF);
  }

  public static baseDataValueRef(o: unknown): o is BaseDataValueRef {
    return Is.baseDataRef(o) && (o as BaseDataValueRef).property !== undefined;
  }

  public static dateRef(o: unknown): o is DateRef {
    return this.ofType(o as TypedObject, CLS.DATE_REF);
  }

  public static relativeDate(o: unknown): o is RelativeDate {
    return this.ofType(o as TypedObject, CLS.RELATIVE_DATE);
  }

  public static fieldConfig(o: unknown): o is FieldConfig {
    return this.ofType(o as TypedObject, CLS.FIELD_CONFIG);
  }

  public static selectionData(o: unknown): o is SelectionData {
    return this.ofType(o as TypedObject, CLS.SELECTION_DATA);
  }

  public static configurationDataExport(o: unknown): o is ExportData {
    return this.ofType(o as TypedObject, CLS.CFG_EXPORT);
  }

  public static successfulRequestSubmission(o: RequestSubmissionResponse): o is SuccessfulRequestSubmission {
    return o.success;
  }

  public static failedRequestSubmission(o: RequestSubmissionResponse): o is FailedRequestSubmission {
    return !o.success;
  }

  public static listDataHandler(o: unknown): o is SimpleListHandler {
    return this.ofType(o as TypedObject, CLS.DATA_HANDLER) && (o as DataHandler).type === DATAHANDLER_TYPES.LIST;
  }

  public static dynamicDataHandler(o: unknown): o is DynamicDataHandler {
    return this.ofType(o as TypedObject, CLS.DATA_HANDLER) && (o as DataHandler).type === DATAHANDLER_TYPES.DYNAMIC;
  }

  public static ofType(o: TypedObject | TypedObject[], name: string): boolean {
    if (!o) {
      return false;
    }
    //validate arrays
    const regEx = new RegExp(/^(.*?)\[\]$/);
    const matches = regEx.exec(name);
    if (matches && matches.length > 0) {
      const singleName = matches[1];
      if (!Array.isArray(o)) {
        return false;
      }
      for (const i of o) {
        if (!Is.ofType(i, singleName)) {
          return false;
        }
      }
      return true;
    }

    if (Array.isArray(o)) {
      return false;
    }

    if (!o.cls) {
      return false;
    }
    return o.cls.toLowerCase() === name.toLowerCase();
  }
}

export class To {
  public static type<T>(o: TypedObject | undefined | unknown, name: string): T | undefined {
    if (!(o as TypedObject)?.cls) {
      return;
    }

    if ((o as TypedObject).cls.toLowerCase() === name.toLowerCase()) {
      return o as unknown as T;
    }
    return;
  }
}
