import {
  AliasValue,
  CalcOperation,
  Calculation,
  CalculationResult,
  CLS,
  DATA_VALUE_TYPE,
  FunctionCommand,
  Is,
  NumericInputValue,
  Value,
  ValueCalcParam,
  ValueCalculation,
} from '../../dtos/index';
import dayjs from 'dayjs';

import { NumberUtil } from '../../common';

const ALLOWED_DATA_VALUE_TYPES = [DATA_VALUE_TYPE.NUMERIC, DATA_VALUE_TYPE.DATE];

export type CalculationOrder = {
  success: boolean;
  calculationOrder: ValueCalculation[];
  failed: string[];
};

/**
 * Class to calculate a calculation
 * For any other calculation related stuff use CalculationHandler
 * Use CalculationResolver to resolve a calculation before using this class
 */
export class Calculator {
  aliasValues: Map<string, number | undefined> = new Map();

  /**
   * Calculate a ValueCalculation which means a calculation where all params are already resolved to a value
   */
  public calc(calculation: ValueCalculation): CalculationResult {
    this.validate(calculation);
    const result = this.calculateResult(calculation);
    this.adaptResult(result, calculation);
    return result;
  }

  /**
   * returns the calculation order for a calculation or a list of unresolvable calculations
   * @param calculation
   */
  public static getCalculationOrder(calculation: ValueCalculation): CalculationOrder {
    const calculationOrder: ValueCalculation[] = [];
    const relations: Record<string, string[]> = {};
    const calculations = new Map<string, ValueCalculation>();

    // find calculation relations
    calculation.params.forEach((valueCalcParam: ValueCalcParam) => {
      if (Is.valueCalculation(valueCalcParam)) {
        calculations.set(valueCalcParam.alias, valueCalcParam);
        relations[valueCalcParam.alias] = valueCalcParam.params
          .filter((param) => Is.aliasValue(param))
          .map((param) => (param as AliasValue).name);
      }
    });

    //try to calculate with relations
    let calulationsDone = 0;
    do {
      calulationsDone = 0;
      calculations.forEach((calculation, alias) => {
        const relationsFulfilled = relations[alias].every((relation) =>
          calculationOrder.find((calc) => calc.alias === relation),
        );
        if (relationsFulfilled) {
          calculationOrder.push(calculation);
          calculations.delete(alias);
          calulationsDone++;
        }
      });
    } while (calulationsDone > 0);

    return {
      success: calculations.size === 0,
      calculationOrder,
      failed: Array.from(calculations.keys()),
    };
  }

  /**
   * calculates the result of a value calculation
   */
  private calculateResult(calculation: ValueCalculation): CalculationResult {
    const calculationResult: CalculationResult = {
      cls: CLS.CALCULATION_RESULT,
      results: [],
      label: undefined,
      value: undefined,
    };

    const calculationOrder = Calculator.getCalculationOrder(calculation);
    if (!calculationOrder.success) {
      throw new Error('Unresolvable calculations found: ' + calculationOrder.failed.join(', '));
    }

    for (const calculation of calculationOrder.calculationOrder) {
      const tmpResult = this.calc(calculation);
      this.aliasValues.set(calculation.alias, tmpResult.value ?? undefined);
    }

    // calculate all other params
    calculation.params.every((valueCalcParam: ValueCalcParam) => {
      const tmpResult = this.paramToCalculationResult(valueCalcParam);
      //continue for excluded results
      if (tmpResult.exclude) {
        return true;
      }
      //if a value is not set we skip without results
      if (tmpResult.value === undefined) {
        calculationResult.results = [];
        calculationResult.value = undefined;
        return false;
      }
      calculationResult.results.push(tmpResult);

      //the first time result will be used as calculation base
      if (calculationResult.value === undefined) {
        calculationResult.value = tmpResult.value;
        return true;
      }

      switch (calculation.op) {
        case CalcOperation.ADD:
          calculationResult.value += tmpResult.value;
          break;
        case CalcOperation.MUL:
          calculationResult.value *= tmpResult.value;
          break;
        case CalcOperation.SUB:
          calculationResult.value -= tmpResult.value;
          break;
        case CalcOperation.DIV:
          if (tmpResult.value === 0) {
            throw new Error('Division by zero');
          }
          calculationResult.value /= tmpResult.value;
          break;
        case CalcOperation.DATEDIFF:
          if (!tmpResult.value) {
            throw new Error('No date given');
          }
          // eslint-disable-next-line no-case-declarations
          const date1 = dayjs(tmpResult.value);
          // eslint-disable-next-line no-case-declarations
          const date2 = dayjs(calculationResult.value);

          if (!date1.isValid() || !date2.isValid()) {
            throw new Error('Invalid date format');
          }
          // eslint-disable-next-line no-case-declarations
          const diff = date1.diff(date2, 'd');
          calculationResult.value = diff;
          break;
        default:
          throw new Error('Unsupported calculation operation ' + calculation.op);
      }
      return true;
    });

    return calculationResult;
  }

  private paramToCalculationResult(param: ValueCalcParam): CalculationResult {
    const tmpResult: CalculationResult = {
      cls: CLS.CALCULATION_RESULT,
      results: [],
      label: undefined,
      value: undefined,
    };

    if (Is.valueCalculation(param)) {
      if (!param.alias || !this.aliasValues.has(param.alias)) {
        throw new Error('Alias does not exist for calculation');
      }
      // all calculations are already done and stored
      const resultValue = this.aliasValues.get(param.alias);

      tmpResult.exclude = param.excludeFromResult;
      // exclude alias value from calculation
      if (!param.excludeFromResult) {
        tmpResult.value = resultValue;
      }

      if (param.label) {
        tmpResult.label = param.label;
      }
    }

    if (Is.aliasValue(param)) {
      if (!this.aliasValues.has(param.name)) {
        throw new Error('Alias does not exist: ' + param.name);
      }
      tmpResult.value = this.aliasValues.get(param.name);

      if (param.label) {
        tmpResult.label = param.label;
      }
    }

    if (Is.value(param)) {
      if (param.type === DATA_VALUE_TYPE.EMPTY) {
        return tmpResult;
      }
      if (ALLOWED_DATA_VALUE_TYPES.indexOf((param as Value).type) === -1) {
        throw new Error(`Invalid value type "${(param as Value).type}"`);
      }
      const dataValue = param as NumericInputValue;
      tmpResult.value = dataValue.value;
      if (dataValue.label) {
        tmpResult.label = dataValue.label;
      }
    }

    return tmpResult;
  }

  /**
   * changes the result value according to the calculation function
   */
  private adaptResult(result: CalculationResult, calculation: ValueCalculation): void {
    if (!result.value || !calculation.fn || !Is.calculationFunction(calculation.fn)) {
      return;
    }
    switch (calculation.fn.command) {
      case FunctionCommand.ROUND:
        // eslint-disable-next-line
        const digits =
          calculation.fn.params && calculation.fn.params.length > 0 ? parseInt(calculation.fn.params[0].toString()) : 0;
        result.value = NumberUtil.round(result.value, digits);
        break;
      case FunctionCommand.FLOOR:
        result.value = Math.floor(result.value);
        break;
      case FunctionCommand.CEIL:
        result.value = Math.ceil(result.value);
        break;
      case FunctionCommand.ABS:
        result.value = Math.abs(result.value);
        break;
      default:
        throw new Error('Unsupported calculation function command ' + calculation.fn.command);
    }
  }

  /**
   * for outside validation use CalculationHandler instead
   * @param calculation
   */
  private validate(calculation: Calculation) {
    if (!calculation.params || calculation.params.length === 0) {
      throw new Error('Empty params are not allowed');
    }

    for (const param of calculation.params) {
      if (Is.calculation(param)) {
        this.validate(param);
        continue;
      }

      if (Is.aliasValue(param)) {
        continue;
      }

      if (!Is.value(param)) {
        throw new TypeError('Unresolved reference ' + JSON.stringify(param));
      }
    }
  }
}
