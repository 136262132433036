import { Persistence } from './persistence';
import { Key } from './key';
import { FormValue } from '@kfd/core';
import { CfgException } from '../../../common/cfg-exception';

export class MemoryPersistence implements Persistence {
  private values = new Map<string, FormValue>();
  private disabledKeys: string[] = [];

  clear(): void {
    this.values.clear();
  }

  enable(key: Key): void {
    if (this.disabledKeys.indexOf(key.toString()) >= 0) {
      this.disabledKeys.splice(this.disabledKeys.indexOf(key.toString()), 1);
    }
  }

  disable(key: Key): void {
    if (this.disabledKeys.indexOf(key.toString()) === -1) {
      this.disabledKeys.push(key.toString());
    }
  }

  get(key: Key): FormValue {
    if (!this.values.has(key.toString())) {
      throw new CfgException('Key not found: ' + key);
    }
    if (this.disabledKeys.indexOf(key.toString()) >= 0) {
      throw new CfgException(`Key "${key.toString()}" not found`);
    }
    return this.values.get(key.toString()) as FormValue;
  }

  has(key: Key): boolean {
    if (this.disabledKeys.indexOf(key.toString()) >= 0) {
      return false;
    }
    return this.values.has(key.toString());
  }

  list(): Key[] {
    const keys = Array.from(this.values.keys()).map((value) => Key.fromString(value));
    return keys.filter((key) => this.disabledKeys.indexOf(key.toString()) === -1);
  }

  set(key: Key, value: FormValue): void {
    this.values.set(key.toString(), value);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  useKey(key: string): void {
    //not yet implemented
  }
}
