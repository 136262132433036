import { Calculation, CalculationRef, CalculationRefResolver } from '@kfd/core';
import { Injectable } from '@angular/core';
import { ConfigurationService } from '../service/configuration.service';

@Injectable()
export class ConfigurationCalculationRefResolver implements CalculationRefResolver {
  constructor(private configurationService: ConfigurationService) {}

  resolve(calculationRef: CalculationRef): Promise<Calculation | undefined> {
    return Promise.resolve(this.configurationService.cfgUtil.getCalculation(calculationRef.name)).then(
      (cfgCalculation) => (cfgCalculation ? cfgCalculation.calculation : undefined),
    );
  }
}
