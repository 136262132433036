import {
  Configuration,
  Create,
  Field,
  FORM_VALUE_TYPES,
  FormValueMap,
  VALIDATION_ERROR_DETAILS,
  VALIDATION_ERROR_TYPE,
  ValidationError,
  ValidationMap,
  ValidationResult,
} from '../../dtos';
import { ConditionValidator } from '../condition';
import { InputValidator } from './input-validator';
import { CfgUtil } from '../cfg-util';

/**
 * Configuration Validator validates the configuration against user inputs
 */
export class ConfigurationValidator {
  constructor(private readonly cfgUtil: CfgUtil<Configuration>) {}

  /**
   * Returns the number of errors in the validation map
   * @param validationMap
   */
  public static errorCount(validationMap: ValidationMap): number {
    let count = 0;
    for (const validationError of validationMap.values()) {
      if (validationError) {
        count++;
      }
    }
    return count;
  }

  /**
   * Returns a map of errors by field names from validation results
   * @param validationMap
   */
  public static errorMap(validationMap: ValidationMap): Map<string, ValidationError> {
    const errorMap = new Map<string, ValidationError>();
    for (const [fieldName, validationError] of validationMap.entries()) {
      if (validationError) {
        errorMap.set(fieldName, validationError);
      }
    }
    return errorMap;
  }

  /**
   * Validates the entire configuration
   * @param formValueMap
   */
  public validateConfiguration(formValueMap: FormValueMap): ValidationMap {
    const fields = this.cfgUtil.getFields();
    return this.validateFields(formValueMap, fields);
  }

  /**
   * Validates a single page of the configuration
   * @param formValueMap
   * @param pageName
   */
  public validatePage(formValueMap: FormValueMap, pageName: string): ValidationMap {
    const fields = this.cfgUtil.getPageFields(pageName);
    return this.validateFields(formValueMap, fields);
  }

  /**
   * Validates a list of fields
   * @param formValueMap
   * @param fields
   */
  public validateFields(formValueMap: FormValueMap, fields: Field[]): ValidationMap {
    const validationErrors: ValidationMap = new Map<string, ValidationError | undefined>();
    for (const field of fields) {
      const validationError = this.validateField(formValueMap, field);
      validationErrors.set(field.name, validationError);
    }
    return validationErrors;
  }

  /**
   * Validates a field by name
   * @param formValueMap
   * @param fieldName
   */
  public validateFieldByName(formValueMap: FormValueMap, fieldName: string): ValidationResult {
    return this.validateField(formValueMap, this.cfgUtil.getFieldByName(fieldName));
  }

  /**
   * Validates a field
   * @param formValueMap
   * @param field
   */
  public validateField(formValueMap: FormValueMap, field: Field | undefined): ValidationResult {
    if (!field) {
      return;
    }
    const conditionGroups = this.cfgUtil.getEntryConditions(field.name);
    let isRelevant = true;
    for (const conditionGroup of conditionGroups) {
      if (!ConditionValidator.evaluateGroup(conditionGroup, formValueMap)) {
        isRelevant = false;
        break;
      }
    }
    if (!isRelevant) {
      return;
    }

    const entryValue = formValueMap.get(field.name);
    if (entryValue === undefined || entryValue.type === FORM_VALUE_TYPES.EMPTY) {
      if (field.config.required) {
        return Create.validationError(VALIDATION_ERROR_TYPE.REQUIRED, VALIDATION_ERROR_DETAILS.EMPTY);
      }
      return;
    }

    return InputValidator.validateField(field.config, entryValue);
  }
}
