import { Observable } from 'rxjs';
import { FormValueMap, PublicProject, RequestForm, RequestSubmissionResponse, SelectionData, Sort } from '@kfd/core';

export const DATA_PROVIDER = 'DataProvider';

export interface DataFilterOptions {
  templateName?: string;
  tags?: string[];
  sort?: Sort[];
}

export interface DataProvider {
  get preview(): boolean;

  set preview(value: boolean);

  get embedded(): boolean;

  set embedded(value: boolean);

  getProjectInfo(projectId: string): Observable<PublicProject>;

  getForm(projectId: string, configurationId: string): Observable<RequestForm>;

  getData(projectId: string, configurationId: string, filter: DataFilterOptions): Observable<SelectionData[]>;

  getDataByIdentifier(
    projectId: string,
    configurationId: string,
    identifier: string,
  ): Observable<SelectionData | undefined>;
  getDataByIdentifier(projectId: string, configurationId: string, identifier: string[]): Observable<SelectionData[]>;

  submitData(requestId: string, valueMap: FormValueMap): Observable<RequestSubmissionResponse>;
}
