import { v4 as uuidv4, validate as validateUuid } from 'uuid';

import { Calculation, CalculationParam, FieldRef, Is } from '../../dtos/index';
import { ArrayUtil, ObjectUtil } from '../../common';

export type CalculationParamDetails = CalculationParam & {
  id: string;
  error?: string;
};
export type CalculationDetails = Omit<Calculation, 'params'> & {
  id: string;
  params: (CalculationParamDetails | CalculationDetails)[];
  valid?: boolean;
  error?: string;
};

/**
 * Helper class to handle all calculation related stuff
 */
export class CalculationHandler {
  private readonly _calculation: Calculation;
  private _aliases: string[] = [];
  private _fieldRefs: FieldRef[] = [];

  constructor(calculation: Calculation) {
    this._calculation = calculation;
    this._aliases = CalculationHandler.findAllAliases(calculation);
  }

  /**
   * filters the list of aliases for the ones which are not part of a circular dependency or the own calculation hierarchy
   */
  public getAllowedAliases(sourceAlias: string): string[] {
    return this._aliases.filter((alias) => {
      const sourceAliasParents = CalculationHandler.getParentAliases(this._calculation, sourceAlias);
      if (!sourceAliasParents) {
        return true;
      }
      //all parents are not allowed
      if (sourceAliasParents.includes(alias)) {
        return false;
      }

      const calculationWithAlias = CalculationHandler.findCalculationByAlias(this._calculation, alias);
      const calculationWithSource = CalculationHandler.findCalculationByAlias(this._calculation, sourceAliasParents[0]);
      if (!calculationWithAlias || !calculationWithSource) {
        return false;
      }
      if (CalculationHandler.hasParamUsingAlias(calculationWithAlias, sourceAliasParents[0])) {
        return false;
      }
      if (CalculationHandler.hasParamUsingAlias(calculationWithSource, sourceAliasParents[0])) {
        return false;
      }

      return true;
    });
  }

  /**
   * adds uuids ids to all calculations and their params
   * @param calculation
   */
  public static addIds(calculation: Calculation): CalculationDetails {
    return {
      ...calculation,
      id: uuidv4().toString(),
      params: calculation.params.map((param) => {
        if (Is.calculation(param)) {
          return this.addIds(param);
        }
        return { ...param, id: uuidv4() };
      }),
    };
  }

  public static removeDetails(calculationDetails: CalculationDetails): Calculation {
    const calculation: Calculation & { id?: string; valid?: boolean } = ObjectUtil.clone(calculationDetails);
    delete calculation.id;
    delete calculation.valid;
    return {
      ...calculation,
      params: calculation.params.map((param: CalculationParam & { id?: string; valid?: boolean }) => {
        if (Is.calculationDetails(param)) {
          return this.removeDetails(param);
        }
        delete param.id;
        delete param.valid;
        return { ...param };
      }),
    };
  }

  public static findCalculationByAlias(
    calculation: Calculation,
    alias: string,
    idToIgnore?: string,
  ): Calculation | undefined {
    if (calculation.alias === alias) {
      if (!idToIgnore || (idToIgnore && (calculation as CalculationDetails).id !== idToIgnore)) {
        return calculation;
      }
    }
    for (const param of calculation.params) {
      if (Is.calculation(param)) {
        const found = this.findCalculationByAlias(param, alias, idToIgnore);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  }

  public static hasParamUsingAlias(calculation: Calculation, alias: string): boolean {
    return !!calculation.params.find((param) => {
      if (Is.calculation(param)) {
        return this.hasParamUsingAlias(param, alias);
      }
      if (Is.aliasValue(param)) {
        return param.name === alias;
      }
      return false;
    });
  }

  /**
   * returns all parent aliases of the given alias
   */
  public static getParentAliases(calculation: Calculation, alias: string): string[] | undefined {
    const aliases: string[] | undefined = [];
    if (calculation.alias === alias) {
      return [calculation.alias];
    }
    for (const param of calculation.params) {
      if (Is.calculation(param)) {
        const subAliases = this.getParentAliases(param, alias);
        if (subAliases && subAliases.length > 0) {
          // aliases = [...aliases, param.alias, ...subAliases];
          return ArrayUtil.distinct([...aliases, param.alias, ...subAliases]);
        }
      }
    }
    return undefined;
  }

  /**
   * returns all aliases in a calculation
   */
  protected static findAllAliases(calculation: Calculation): string[] {
    let aliases: string[] = [];
    if (calculation.alias && !validateUuid(calculation.alias)) {
      aliases = [calculation.alias];
    }
    calculation.params.forEach((param) => {
      if (Is.calculation(param)) {
        aliases = [...aliases, ...CalculationHandler.findAllAliases(param)];
      }
    });
    return aliases;
  }

  public getCalculation(): Calculation {
    return this._calculation;
  }

  public getAliases(): string[] {
    return this._aliases;
  }

  public getFieldRefs(): FieldRef[] {
    return this._fieldRefs;
  }
}
