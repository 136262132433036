import {
  BaseDataRef,
  BaseInputValue,
  CLS,
  DATA_TYPES,
  Image,
  MetaData,
  ProjectCI,
  ProjectInfo,
  SelectionValue,
} from './common.dto';
import { ScopedUserRef, USER_SCOPES } from './web.dto';
import { BaseDataFilter, ConfigurationExport, Id } from './cms.dto';
import { Data, Info } from './cfg.dto';

export interface LogData {
  msg: string;
  severity: 'error' | 'warn' | 'log';
  stack?: string;
  browser?: string;
  url?: string;
}

export interface CreateProjectData {
  name: string;
  label: string;
  info?: ProjectInfo;
}

export interface CreateConfigurationData {
  name: string;
  label: string;
  code: string;
}

export interface CreateBaseDataValue {
  name: string;
  label: string;
  inputValue: BaseInputValue;
}

export interface CreateBaseData {
  name: string;
  label: string;
  values: CreateBaseDataValue[];
  description?: string;
  image?: Id;
  tags?: string[];
  templateName?: string;
}

export interface CreateAssetData {
  name: string;
  label: string;
  data: string;
}

export interface CmsUserProject {
  _id: Id;
  name: string;
  label: string;
  info?: ProjectInfo;
  ci?: ProjectCI;
}

export interface MyProject extends CmsUserProject {
  scopes: USER_SCOPES[];
}

export interface ApiMandantForMembers extends CmsUserProject {
  users: ScopedUserRef[];
}

export interface ApiDataExport {
  cls: CLS.SELECTION_DATA;
  type: DATA_TYPES.SELECTION;
  name: string;
  label: string;
  value?: SelectionValue;
  tags?: string[];
  image?: Image;
  description?: string;
  info?: Info;
}

export interface ApiConfigurationExport {
  date: Date;
  configuration: ConfigurationExport;
  data: ApiDataExport[];
}

export interface UsedBy {
  cls: CLS;
  label?: string;
  name: string;
  usageType?: string;
}

export interface EntryUsage<T> {
  usedBy: UsedBy;
  entry: T;
}

export interface ConfigurationInfo {
  id: Id;
  name: string;
  label: string;
  version: number;
}

export interface ConfigurationEntryUsage {
  configuration: ConfigurationInfo;
  usage: EntryUsage<BaseDataRef>[];
}

export interface DynamicUsage {
  filter: BaseDataFilter;
  usages: ConfigurationEntryUsage[];
}

export interface DataEntryUsage {
  dynamic: DynamicUsage[];
  static: ConfigurationEntryUsage[];
}

export interface AssetUsage {
  inUse: boolean;
  projectLogo: boolean;
  baseData: Data[];
  configurations: ConfigurationInfo[];
}

export enum AssetType {
  IMAGE = 'image',
  DOCUMENT = 'document',
}

export interface Asset {
  _id: Id;
  name: string;
  label: string;
  type: AssetType;
  size: number;
  url: string;
  thumbnailUrl: string;
  meta: MetaData;
}
