import { Configuration, SelectionData, Sort } from './cfg.dto';
import { BaseDataTemplate, ConfigurationIdentifier, FormValueMap, InternalSettings, RequestCode } from './cms-cfg.dto';
import { CLS } from './common.dto';
import { ObjectId } from 'mongodb';
import { AssetType } from './cms-service.dto';

export enum DATA_TYPE {
  CONFIGURATION = 'configuration',
  DATA = 'data',
}

export interface ConfigurationExport extends Configuration {
  code: string;
  internalSettings?: InternalSettings;
}

/**
 * contains only public data
 */
export interface PublicConfigurationEntry {
  _id: Id;
  projectId: Id;
  configurationId: Id;
  version: number;
  configuration: Configuration;
}

/**
 * published configuration entry with public and internal data
 */
export interface PublishedConfigurationEntry {
  _id: Id;
  projectId: Id;
  configurationId: Id;
  version: number;
  configuration: ConfigurationExport;
}

export interface BaseDataExport extends Omit<SelectionData, 'image'> {
  image: string;
}

export type BaseDataTemplateExport = BaseDataTemplate;

export interface AssetExport {
  cls: CLS.ASSET_EXPORT;
  name: string;
  label: string;
  type: AssetType;
  blob: string;
}

export interface ExportData {
  cls: CLS.CFG_EXPORT;
  date: Date;
  configurations?: ConfigurationExport[];
  data?: BaseDataExport[];
  baseDataTemplates?: BaseDataTemplate[];
  assets?: AssetExport[];
}

export type ImportStatus = 'success' | 'failed' | 'exists';

export interface EntryImportResult {
  id?: Id;
  name: string;
  status: ImportStatus;
  messages?: string[];
}

export type ConfigurationImportResult = EntryImportResult;
export type DataImportResult = EntryImportResult;
export type BaseDataTemplatesImportResult = EntryImportResult;
export type AssetsImportResult = EntryImportResult;

export type ImportResultStatus = 'success' | 'warnings' | 'failed';

export interface ImportResult {
  status: ImportResultStatus;
  cfg: ConfigurationImportResult[];
  data: DataImportResult[];
  baseDataTemplates: BaseDataTemplatesImportResult[];
  assets: AssetsImportResult[];
}

export interface StatusLog {
  status: string;
  date: Date;
  user: Id;
  comment?: string;
}

export interface EmailVerification {
  email: string;
  date: Date;
  code: string;
}

// customer settings for a specific request (public for customer)
export interface CustomerRequestSettings {
  enableEmailNotifications?: boolean;
  emailVerification?: EmailVerification;
  email?: string;
}

export interface CustomerRequest {
  _id: Id;
  projectId: string;
  code: RequestCode;
  configuration: ConfigurationIdentifier;
  title?: string[];
  formValueMap: FormValueMap;
  customerCode: string;
  currentState: string;
  currentUser?: string;
  createdDate: Date;
  statusLog: StatusLog[];
  customerSettings?: CustomerRequestSettings;
}

// information for users (not internal)
export interface CustomerRequestInfo {
  formValueMap: FormValueMap;
  currentState: string;
  createdDate: Date;
  configuration: Configuration;
  customerSettings?: CustomerRequestSettings;
}

export interface BaseFilter<T> {
  cls: CLS.FILTER;
  id?: string;
  label?: string;
  filterValues: T | NonNullable<unknown>;
}

export interface CustomerRequestFilter {
  cfgCode?: string[];
  text?: string;
  state?: string[];
  userId?: string[];
  dateFrom?: string;
  dateTo?: string;
  sortBy?: 'createdDate+' | 'createdDate-' | 'updatedDate+' | 'updatedDate-';
}

export interface StatisticResult {
  labels: string[];
  data: number[];
}

export enum StatisticRange {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export enum UsageType {
  LOAD = 'load',
  REQUEST = 'request',
}

export interface UsageLog {
  _id: Id;
  projectId: Id;
  configurationId: Id;
  createdDate: Date;
  type: UsageType;
  browser: Record<string, unknown>;
}

export interface AuditLog {
  _id?: string;
  createdDate: Date;
  requestId: string;
  projectId?: Id;
  userId?: Id;
  method: string;
  action: string;
  context: string;
  params?: Record<string, unknown>;
  body?: Record<string, unknown> | Record<string, unknown>[];

  state?: {
    type: string;
    time: Date;
    error?: string;
  };
}

export enum Severity {
  trace,
  info,
  warn,
  error,
  fatal,
}

export const SEVERITY_NAMES = ['trace', 'info', 'warning', 'error', 'fatal'];
export const SEVERITY_LABELS = ['Trace', 'Info', 'Warn', 'Error', 'Fatal'];

export interface ErrorLog {
  _id?: string;
  date: Date;
  service: string;
  message: string;
  errorId: string;
  data: Record<string, unknown>;
  severity: Severity;
}

export interface ErrorLogFilter {
  severity?: Severity;
  from?: string;
  to?: string;
  text?: string;
}

export interface AuditLogFilter {
  projectId?: string;

  text?: string;
}

export const ERROR_LOG_FILTER_DEFAULT: ErrorLogFilter = {
  severity: Severity.error,
};

export type Id = string | ObjectId;

export interface MovePosition {
  //parent element name
  name: string;
  pos: number;
}

export interface Link {
  target: string;
  text: string;
}

export enum NotificationScope {
  PROJECT = 'pro',
  CONFIGURATIONS = 'cfg',
  CUSTOMER_REQUESTS = 'req',
  ACCOUNT = 'acc',
}

export enum NotificationSeverity {
  WARN = 'warn',
  INFO = 'info',
  URGENT = 'urgent',
}

export interface Notification {
  _id: Id;
  recipient: Id;
  scope: NotificationScope;
  severity: NotificationSeverity;
  date: Date;
  subject: string;
  message: string;
  readDate?: string;
  link?: Link;
  projectId?: Id;
}

export enum WsMessageType {
  NOTIFICATION = 'notification',
}

export interface WsMessage<T = Record<string, unknown>> {
  type: WsMessageType;
  data: T;
}

export interface WsNotifcationMessage extends WsMessage<Notification> {
  type: WsMessageType.NOTIFICATION;
}

export enum AVAILABLE_FEATURES {
  ADMIN_TOOLS = 'admin-tools',
  CFG_IMPORT = 'cfg-import',
  CFG_EXPORT = 'cfg-export',
}

export interface SelectOption<T = string | number | boolean> {
  // null is only allowed for empty values
  value: T | null;
  identifier?: string;
  label: string;
  description?: string;
  icon?: string;
}

export interface ParentSelectOption<T = string | number | boolean> extends SelectOption {
  items: SelectOption<T>[];
}

export enum CR_PREDEFINED_STATES {
  NEW = 'new',
  DONE = 'done',
  CANCELED_BY_CUSTOMER = 'canceled',
}

export interface UserSetting {
  key: SETTINGS_KEYS;
  value: string;
  projectId?: Id;
}

export enum SETTINGS_KEYS {
  DISABLE_MESSAGES_CFG_PUBLISHED = 'DISABLE_MESSAGES_CFG_PUBLISHED',
  DISABLE_MESSAGES_NEW_CUSTOMER_REQUESTS = 'DISABLE_MESSAGES_NEW_CUSTOMER_REQUESTS',
  CUSTOMER_REQUESTS_FILTERS = 'CUSTOMER_REQUESTS_FILTERS',
}

export interface BaseDataFilter {
  templateName?: string;
  tags?: string[];
}

export interface BaseDataFilterAndSort extends BaseDataFilter {
  sortParam?: Sort[];
}
