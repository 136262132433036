<div *ngIf="configuratorPage; else notInitialized" [attr.data-automationId]="'cfg-page-' + configuratorPage.name">
  <h3 *ngIf="!configuratorPage.hideLabel" data-automationId="cfg-page-label">{{ configuratorPage.label }}</h3>

  <kfd-configurator-info [info]="configuratorPage.info"></kfd-configurator-info>

  <ng-container *ngFor="let child of configuratorPage.children">
    <kfd-configurator-condition [name]="child.entry.name">
      <ng-template #caseTrue>
        @if (!child.entry.hide) {
          @switch (child.entry.cls) {
            @case (cls.FIELD_GROUP) {
              <kfd-configurator-question [question]="$any(child.entry)"></kfd-configurator-question>
            }
            @case (cls.FIELD) {
              <kfd-configurator-field [field]="$any(child.entry)"></kfd-configurator-field>
            }
          }
        }
      </ng-template>
    </kfd-configurator-condition>
  </ng-container>
</div>

<ng-template #notInitialized> ---</ng-template>
