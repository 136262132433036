import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withRouterConfig } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { App, Service } from '@kfd/core';
import { CFG_CONTEXT_SERVICE, CfgContextService } from '@kfd/cfg-core';
import { ENDPOINT_SERVICE_TOKEN, WebCoreModule } from '@kfd/web-core';
import { environment } from '../environments/environment';

export const appConfig: ApplicationConfig = {
  providers: [
    provideClientHydration(),
    provideRouter(appRoutes, withRouterConfig({ paramsInheritanceStrategy: 'always' })),
    importProvidersFrom(
      BrowserAnimationsModule,
      HttpClientModule,
      WebCoreModule.forRoot({
        config: {
          services: {
            [Service.CMS_SERVICE]: environment.services.cms,
            [Service.AUTH_SERVICE]: environment.services.auth,
            [Service.CFG_SERVICE]: environment.services.cfg,
            [Service.CONTENT_SERVICE]: environment.services.content,
          },
          apps: {
            [App.WEB]: environment.apps.web,
            [App.CFG]: environment.apps.cfg,
          },
        },
      }),
    ),
    {
      provide: ENDPOINT_SERVICE_TOKEN,
      useValue: {
        services: {
          [Service.CFG_SERVICE]: environment.services.cfg,
        },
      },
    },
    // context service is required for all modules to provide project id
    {
      provide: CFG_CONTEXT_SERVICE,
      useClass: CfgContextService,
    },
  ],
};
