import { Inject, Injectable } from '@angular/core';
import {
  BASE_DATA_RESOLVER,
  BaseDataResolver,
  Calculation,
  CalculationResolver,
  CalculationResult,
  Calculator,
} from '@kfd/core';
import { Observable } from 'rxjs';
import { ConfigurationFieldRefResolver } from './configuration-fieldref-resolver';
import { ConfigurationService } from './configuration.service';
import { ConfigurationCalculationRefResolver } from './configuration-calculation-ref-resolver';

@Injectable()
export class CalculationService {
  private readonly calculationResolver: CalculationResolver;
  private calculator: Calculator;

  constructor(
    private readonly configurationService: ConfigurationService,
    readonly fieldRefResolver: ConfigurationFieldRefResolver,
    readonly calculationRefResolver: ConfigurationCalculationRefResolver,
    @Inject(BASE_DATA_RESOLVER) readonly baseDataResolver: BaseDataResolver,
  ) {
    this.calculationResolver = new CalculationResolver(fieldRefResolver, baseDataResolver, calculationRefResolver);
    this.calculator = new Calculator();
  }

  public calc(calculation: Calculation): Observable<CalculationResult> {
    return new Observable((observer) => {
      //todo get list of relevant fields (eg condition service??)
      const relevantFields = this.configurationService.cfgUtil.getFieldNames();
      this.calculationResolver.resolve(calculation, relevantFields).then((valueCalculation) => {
        try {
          const result = this.calculator.calc(valueCalculation);
          observer.next(result);
          observer.complete();
        } catch (e) {
          observer.error(e);
          observer.complete();
        }
      });
    });
  }
}
