<ng-container *ngIf="configuration && pageInfo$ && (pageInfo$ | async) as pageInfo">
  <div class="form-header">
    <div class="width-container">
      <h2 data-automationId="configuration-label">{{ configuration.label }}</h2>
    </div>
  </div>
  <div [attr.data-automationId]="'configuration-' + configuration.name" class="content">
    <div class="width-container">
      @if (pageInfo.currentPage) {
        <ng-container *ngFor="let configuratorPage of configuration.children">
          @if (!configuratorPage.entry.hide && configuratorPage.entry.name === pageInfo.currentPage.name) {
            <kfd-configurator-condition [name]="configuratorPage.entry.name">
              <ng-template #caseTrue>
                <kfd-configurator-page [configuratorPage]="configuratorPage.entry"></kfd-configurator-page>
              </ng-template>
            </kfd-configurator-condition>
          }
        </ng-container>
      } @else {
        <i>No Page found</i>
      }
    </div>
  </div>
  <div class="form-footer mt-4">
    <div class="width-container flex flex-row my-2">
      <button
        (click)="previous()"
        *ngIf="pageInfo.hasPrev"
        [attr.data-automationId]="'cfg-page-btn-prev'"
        icon="pi {{ CFG_ICON.PREV }}"
        iconPos="left"
        label="Zurück"
        pButton
      ></button>
      <div class="flex-1"></div>
      <button
        (click)="next(pageInfo)"
        *ngIf="pageInfo.hasNext"
        [attr.data-automationId]="'cfg-page-btn-next'"
        icon="pi {{ CFG_ICON.NEXT }}"
        label="Weiter"
        pButton
      ></button>
    </div>
  </div>
</ng-container>
