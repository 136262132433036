@if (screenSize$ | async; as screenSize) {
  <ul class="selection-list grid">
    @for (inlineSelectOption of inlineSelectOptions; track inlineSelectOption.id) {
      <li
        class="select-option"
        [ngClass]="{
          'col-6': screenSize === ScreenSize.XS,
          'col-4': screenSize === ScreenSize.MD,
          'col-3': screenSize === ScreenSize.LG,
          'width-image': !!inlineSelectOption.image,
          'label-only': !inlineSelectOption.image && inlineSelectOption.additionalProperties.length === 0,
          selected: inlineSelectOption.selected
        }"
      >
        <div class="content">
          <div
            class="header"
            (click)="
              inlineSelectOption.selected ? deSelectOption(inlineSelectOption) : selectOption(inlineSelectOption)
            "
          >
            <div class="label">{{ inlineSelectOption.label }}</div>
            <p-checkbox [ngModel]="inlineSelectOption.selected" binary="true"></p-checkbox>
          </div>
          @if (inlineSelectOption.image) {
            <div class="image">
              <img [src]="inlineSelectOption.image" />
            </div>
          }
          @if (inlineSelectOption.additionalProperties.length > 0) {
            <div class="additional-values">
              @for (value of inlineSelectOption.additionalProperties; track value.label) {
                <div class="value">{{ value.label }}: {{ value.value }}</div>
              }
            </div>
          }
        </div>
      </li>
    }
  </ul>
}
