@if (field && field.dataHandler && selectionDataList.length > 0) {
  @switch (field.layout?.type) {
    @case (layouts.BUTTON) {
      <kfd-web-inline-selection
        [options]="selectionDataList"
        [minSelections]="getMin()"
        [maxSelections]="getMax()"
        [additionalValues]="field.additionalValues"
        optionId="name"
        optionImage="image.thumbnail"
        [selection]="selection"
        (selectionChange)="onSelectionChange($event)"
      ></kfd-web-inline-selection>
    }
    @default {
      @if (singleSelection) {
        <p-dropdown
          (onChange)="onSelectionChange($event.value)"
          [filter]="false"
          [ngModel]="selection.length > 0 ? selection[0] : emptyName"
          [options]="selectionDataList"
          [overlayOptions]="overlayOptions"
          data-automationId="cfg-field-select-singledropdown"
          optionLabel="label"
          optionValue="name"
        >
          <ng-template let-option pTemplate="selectedItem">
            <ng-template [ngTemplateOutletContext]="{ option: option }" [ngTemplateOutlet]="optionTpl"></ng-template>
          </ng-template>
          <ng-template let-option pTemplate="item">
            <ng-template [ngTemplateOutletContext]="{ option: option }" [ngTemplateOutlet]="optionTpl"></ng-template>
          </ng-template>
        </p-dropdown>
      } @else {
        <p-multiSelect
          (onChange)="onSelectionChange($event.value)"
          [appendTo]="!configService.embedded ? 'body' : undefined"
          [ngModel]="selection"
          [options]="selectionDataList"
          [selectionLimit]="getMax()"
          data-automationId="cfg-field-select-multi"
          optionLabel="label"
          optionValue="name"
        >
          <ng-template let-option pTemplate="item">
            <ng-template [ngTemplateOutletContext]="{ option: option }" [ngTemplateOutlet]="optionTpl"></ng-template>
          </ng-template>
        </p-multiSelect>
      }
    }
  }

  <ng-template #optionTpl let-option="option">
    <ng-container *ngIf="option">
      <div
        [attr.data-option-name]="option.name"
        class="option-item"
        [ngClass]="{ 'second-row': field.additionalValues && field.additionalValues.length > 0 }"
      >
        @if (option.image) {
          <img [src]="option.image.thumbnail ? option.image.thumbnail : option.image.url" />
        }
        <div class="content">
          <div class="label">{{ option.label }}</div>
          @if (field.additionalValues && field.additionalValues.length > 0) {
            <div class="additional-values">
              @for (value of field.additionalValues; track value.label) {
                <div class="value">{{ value.label }}: {{ findOptionValue(option.values, value.value) }}</div>
              }
            </div>
          }
        </div>
      </div>
    </ng-container>
  </ng-template>
} @else {
  <div class="flex justify-content-center">
    <i class="color-disabled">Keine Auswahloptionen vorhanden</i>
  </div>
}
