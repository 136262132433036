@if (question) {
  <div [attr.data-automationId]="'cfg-group-' + question.name">
    <h4 *ngIf="!question.hideLabel" class="group-title">{{ question.label }}</h4>
    <kfd-configurator-info *ngIf="question.info" [info]="question.info"></kfd-configurator-info>
    <kfd-configurator-condition *ngFor="let questionField of question.children" [name]="questionField.entry.name">
      <ng-template #caseTrue>
        @if (!questionField.entry.hide) {
          <kfd-configurator-field [field]="questionField.entry"></kfd-configurator-field>
        }
      </ng-template>
    </kfd-configurator-condition>
  </div>
} @else {
  - group not found -
}
